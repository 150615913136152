import { EventType, PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";

import { KEYS } from "./constants";
import { loginRequest, msalConfig } from "./configs/authConfig";
import { removeFromLocalStorage, setInLocalStorage } from "./utils/localstorage";
import store from "store/store";
import { logoutNav } from "store/navSlice";
import { logoutUser } from "store/userSlice";

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
	} else if (event.eventType === EventType.LOGOUT_START || event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        signOut();  // Dispatch logout action and clear localStorage
    }
});

export const loginUser = async (intendedUrl) => {
    try {
        await msalInstance.loginRedirect({ ...loginRequest, state: intendedUrl, prompt: 'create' });

        return ["", "success"]; //[err, resp]
    } catch (error) {
        return [error, ""];
    }
}

export const signOut = async () => {
    try {
        await msalInstance.logoutRedirect();

        removeFromLocalStorage(KEYS.ACCESS_TOKEN);
        store.dispatch(logoutUser());
        store.dispatch(logoutNav());

        return ["", "success"]; //[err, resp]
    } catch (error) {
        return [error, ""];
    }
}

export const getAccessToken = async () => {
    const activeAccount = msalInstance.getActiveAccount();
    const accessTokenRequest = {
        scopes: ['c73d76aa-6c31-4ef9-99fb-4ab02741f658/.default'],
        account: activeAccount
    };

    try {
        const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);

        // Acquire token silent success
        const accessToken = accessTokenResponse.accessToken;
        const data = {
            accessToken
        };

        setInLocalStorage(KEYS.ACCESS_TOKEN, accessToken);

        return ["", data];
    } catch (silentError) {
        if (silentError instanceof InteractionRequiredAuthError) {
            try {
                const accessTokenResponse = await msalInstance.acquireTokenPopup(accessTokenRequest);

                // Acquire token interactive success
                const accessToken = accessTokenResponse.accessToken;
                const data = {
                    accessToken
                };

                setInLocalStorage("accessToken", accessToken);

                return ["", data];
            } catch (interactiveError) {
                // Acquire token interactive failure
                
                return [interactiveError, null]; 
            }
        } else {
            // Handle other silent error cases
            return [silentError, null];
        }
    }
}
